<template>
  <div class="card">
    <div
      class="card-header d-flex flex-column align-items-start justify-content-start"
      v-if="questionnaire !== null"
    >
      <h2 class="text-primary">{{ questionnaire.title }}</h2>
      <p>{{ questionnaire.description }}</p>
    </div>

    <div class="card-body" v-if="questions !== null">
      <table role="table" class="table">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col">Name</th>
            <th role="columnheader" scope="col">Description</th>
            <th role="columnheader" scope="col" class="text-center">Response</th>
            <th role="columnheader" scope="col" class="text-center">
              Response Mandatory
            </th>
            <th role="columnheader" scope="col" class="text-center">Updated At</th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr v-for="question in questions" :key="question._id" role="row">
            <td role="cell" style="width: 300px">
              <div class="d-flex justify-content-start" style="width: 300px">
                <p class="text-left text-primary font-weight-bold mb-0">
                  {{ question.title }}
                </p>
              </div>
            </td>

            <td role="cell" style="width: 300px">
              <div class="d-flex justify-content-start" style="width: 300px">
                <p class="text-left font-italic font-weight-bold mb-0">
                  {{ question.description }}
                </p>
              </div>
            </td>

            <td role="cell">
              <div class="d-flex justify-content-center">
                <b-badge variant="primary">{{
                  getResponseValue(question.question_response)
                }}</b-badge>
              </div>
            </td>

            <td role="cell">
              <div class="d-flex justify-content-center">
                <b-avatar
                  :text="getMandatoryValue(question.response_mandatory)"
                  :variant="
                    question.response_mandatory == 1 ? 'light-success' : 'light-danger'
                  "
                />
              </div>
            </td>

            <td aria-colindex="2" role="cell">
              <div class="d-flex justify-content-center">
                <p class="text-center font-weight-bold mb-0">
                  {{ question.updated_at | moment }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import ThirdPartyRisksMixins from "../../../mixins/ThirdPartyRisksMixins";
import { BBadge, BOverlay, BAvatar } from "bootstrap-vue";
import TaskMixins from "../../../mixins/TaskMixins";
import moment from "moment";

export default {
  components: { BBadge, BOverlay, BAvatar },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  data() {
    return {
      questionnaire: null,
      questions: null,
    };
  },
  mixins: [ResponseMixins, ThirdPartyRisksMixins, TaskMixins],
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getQuestionnaireDetailsAndSetData(this.$route.params.id);
    },
    getQuestionnaireDetailsAndSetData(questionnaireId) {
      this.getNonImportedQuestionnaireDetails({}, questionnaireId)
        .then((res) => {
          console.log(res);
          this.questionnaire = res.data.data.questionnaire;
          this.questions = res.data.data.questions;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
    getResponseValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 2:
            return "No";
          case 3:
            return "Not Applicable";
          default:
            return null;
        }
      }
    },
    getMandatoryValue(num) {
      if (num !== null) {
        let newNum = Number(num);
        switch (newNum) {
          case 1:
            return "Yes";
          case 0:
            return "No";
          default:
            return "No";
        }
      }
    },
  },
};
</script>

<style></style>
